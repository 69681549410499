/**
 * Implement Gatsby's Browser APIs in this file.
 * Browser API
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

// You can delete this file if you're not using it
import './src/components/App/assets/css/bootstrap.min.css';
import './src/components/App/assets/css/animate.min.css';
import './src/components/App/assets/css/boxicons.min.css';
import './src/components/App/assets/css/flaticon.css';
import './node_modules/react-modal-video/css/modal-video.min.css'
import "react-datepicker/dist/react-datepicker.css"
import './src/components/App/assets/css/style.css';
import './src/components/App/assets/css/responsive.css';